.section {
  z-index: 3;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.header {
  width: 480px;
  padding-top: 78px;
  white-space: break-spaces;
  color: #1A1A1A;
  font-family: var(--syne-font);
  font-size: 42px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.4px;
  transform: scaleX(1.1);
  transform-origin: left;
  padding-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 520px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  justify-content: center;
  &._1 {
    justify-content: flex-start;
    height: 646px;
    background-size: auto 80%;
    background-image: url(../citizenship_image@2x.jpg);
    background-position: right bottom;
    .inner {
      align-self: flex-start;
    }
  }
  &._2 {
    background-image: url(../createyourown_image@2x.jpg);
    background-position: left;
    .inner {
      align-self: flex-end;
    }
  }
  &._3 {
    background-image: url(../socialexperience_image@2x.jpg);
    background-position: right;
    .inner {
      align-self: flex-start;
    }
  }
  .container {
    width: 100%;
    max-width: var(--container-width);
    display: flex;
    flex-direction: column;
    .inner {
      width: 100%;
      max-width: 420px;
      gap: 36px;
      display: flex;
      flex-direction: column;
    }
    .icon {
      width: 82px;
    }
    .title {
      white-space: break-spaces;
      color: #1A1A1A;
      font-family: var(--syne-font);
      font-size: 35px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.4px;
      transform: scaleX(1.1);
      transform-origin: left;
    }
    .text {
      color: black;
      font-family: var(--hdr-font);
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.join {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(250,214,188);
  background: linear-gradient(90deg, rgba(250,214,188,1) 0%, rgba(250,214,188,1) 50%, rgba(139,249,186,1) 50%, rgba(139,249,186,1) 100%);
  .container {
    background-image: url(../cta_join@2x.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 1200px;
    height: 884px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 42px;
      color: #1A1A1A;
      font-family: var(--syne-font);
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.4px;
      transform: scaleX(1.1);
      transform-origin: left;
    }
  }
}
