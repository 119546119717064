.selected-flag {
    background-color: transparent !important;
}
.iti-flag {
    margin-top: 0px;
    margin-left: 16px;
}
.intl-tel-input .country-list .flag-box {
    margin-right: 24px;
}
.intl-tel-input .country-list .country-name {
    margin-right: 5px;
}
.is-invalid [type="tel"] {
    border: 1px solid #F44949;
}

//.iti-flag {
//    margin-top: 15px;
//}
//.selected-dial-code {
//    margin-left: 8px;
//    padding-left:8px !important;
//}
//.intl-tel-input.allow-dropdown .selected-flag {
//    width: 56px;
//    padding-left: 16px;
//
//    .arrow {
//        padding-right: 8px;
//        top: -4px;
//        position: relative;
//    }
//    &:after {
//        content: '';
//        width: 1px;
//        height: 22px;
//        background: #B5BDCA;
//    }
//}
//input[type=tel] {
//    padding-left: 81px;
//}
