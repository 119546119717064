.wraper,
.wraper :global(*) {
  box-sizing: border-box;
}
.wrapper {
  z-index: 3;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding-top: 144px;
  padding-bottom: 120px;
  gap: 120px;
}

.section {
  position: relative;
  width: 100%;
  max-width: var(--container-width);
  display: flex;
  flex-direction: column;
  
  &.left, &.right {
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.left {
    padding-top: 80px;
    padding-bottom: 160px;
    background-position: right;
    background-image: url(../play_create_image@2x.jpg);
    .inner {
      align-self: start;
    }
  }
  &.right {
    padding-top: 160px;
    padding-bottom: 80px;
    background-position: left;
    background-image: url(../metaverse_image@2x.jpg);
    .inner {
      align-self: end;
    }
  }

  .title, .text {
    color: white;
    white-space: break-spaces;
  }
  .inner {
    max-width: 530px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .title {
    font-family: var(--syne-font);
    font-size: 42px;
    line-height: 1;
    letter-spacing: 0.4px;
    transform: scaleX(1.1);
    transform-origin: left;
    font-weight: 600;
  }
  .text {
    font-family: var(--hdr-font);
    font-size: 18px;
  }
}

