.footer {
  display: flex;
  width: 1224px;
  position: relative;

  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  &, .footer * {
    box-sizing: border-box;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
  }
  &__address {
    color: #505D77;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &__menu {
    flex-shrink: 0;
    width: 603px;
    height: 32px;
    position: relative;
    display: flex;
    gap: 32px;
  }
  &__item {
    color: #353451;
    text-align: left;
    font: 400 16px/200% "Rubik", sans-serif;
    &:hover, &:focus, &:active, &.active {
      color: #7000BF;
    }
  }

  &__copyright {
    color: #353451;
    text-align: left;
    font: 400 14px/200% "Rubik", sans-serif;
    position: relative;
  }
}

.vlFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 92px 0;
  background-image: url(../watermark_logo.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  &Container {
    width: 100%;
    max-width: var(--container-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &Pdf {
    align-items: flex-end;
    display: inline-flex;
    // flex-direction: column;
    gap: 16px;
    a {
      white-space: nowrap;
      text-align: right;
      color: #1A1A1A;
      font-family: var(--hdr-font);
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &Logo {
    img {
      width: 338px;
      height: auto;
    }
  }
  &Right {
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 480px;
  }
  &Menu {
    display: inline-flex;
    &Item {
      text-align: right;
      color: #1A1A1A;
      font-family: var(--hdr-font);
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      &.active {
        text-decoration: underline;
      }
      &:first-child{
        &::before {
          display: none;
        }
      }
      &::before {
        pointer-events: none;
        content: "I";
        display: inline-block;
        margin: 0 16px;
      }
    }
  }
  &Address {
    white-space: break-spaces;
    text-align: right;
    color: #1A1A1A;
    font-family: var(--hdr-font);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
  }
  &Copyright {
    white-space: break-spaces;
    text-align: right;
    color: #1A1A1A;
    font-family: var(--hdr-font);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
  }
}

