:root {
  --landing-top-height: 700px;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .dark-bg {
    width: 100%;
    height: 180px;
    background-image: url('../../hero@2x.jpg');
    background-size: auto 100%;
    background-position: center;
    background-repeat: repeat-x;
  }
  &:global(.black) {
    background-color: #000;
  }
}

.landing {
  &__menu {
    &__wrap {
      z-index: 10;
      width: 100%;
      padding: 16px;
      position: relative;
      max-width: 1200px;
    }
  }
}

.top,
.top * {
  box-sizing: border-box;
}
.top_bg {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  height: 700px;
  width: 100%;
  top: 0px;
  left: 0px;
  // background: linear-gradient(
  //   158.68deg,
  //   rgba(71, 83, 191, 1) 0%,
  //   rgba(255, 255, 255, 0.55) 100%
  // );
  background-image: url('../../Vlands_Web_hero.jpg');
  background-position: top center;
  background-repeat: repeat-x;
  background-size: 1248px;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5) {
    background-image: url('../../Vlands_Web_hero@2x.jpg');
  }
}
.top {
  // background: linear-gradient(
  //   158.68deg,
  //   rgba(71, 83, 191, 1) 0%,
  //   rgba(255, 255, 255, 0.55) 100%
  // );
  height: var(--landing-top-height);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.blur {
  pointer-events: none;
  width: 1600px;
  height: 872px;
  position: absolute;
  left: calc(50% - 804px);
  top: 26px;
}
.ellipse-4 {
  background: #28bdde;
  border-radius: 50%;
  opacity: 0.5;
  width: 657px;
  height: 657px;
  position: absolute;
  left: 0px;
  top: 0px;
  filter: blur(200px);
}
.ellipse-3 {
  background: #a428de;
  border-radius: 50%;
  opacity: 0.699999988079071;
  width: 728px;
  height: 872px;
  position: absolute;
  right: 0px;
  top: 0px;
  filter: blur(200px);
}
.img {
  pointer-events: none;
  pointer-events: none;
  user-select: none;
  width: 851px;
  height: 790px;
  position: absolute;
  // left: 721px;
  // top: 104px;
  left: 420px;
  top: 105px;
  z-index: 2;
}
._1-1 {
  width: 851px;
  height: 773px;
  position: absolute;
  left: 0px;
  top: -3px;
}
.ellipse-5 {
  width: 674px;
  height: 675px;
  position: absolute;
  left: 121px;
  top: 95px;
}
.new-world {
  display: none;
  width: 196.24px;
  height: 129.91px;
  position: absolute;
  left: 357px;
  top: 360.83px;
  transform-origin: 0 0;
  transform: rotate(-6.092deg) scale(1, 1);
}
.frame-4116 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 34.56px;
  top: 28.27px;
}
.we-build {
  color: #ffffff;
  text-align: left;
  font: 900 16px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.frame-4117 {
  background: #3f50ec;
  border-radius: 12px;
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 12.29px;
  top: 56.04px;
}
.new-worlds {
  color: #ffffff;
  text-align: left;
  font: 900 16px/150% "Nunito", sans-serif;
  text-transform: uppercase;
  position: relative;
}
.title {
  width: 100%;
  max-width: var(--container-width);
  display: flex;
  flex-direction: column;
  gap: 65px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 200px;
  z-index: 2;
}
.frame-4225 {
  user-select: none;
  flex-shrink: 0;
  width: 798px;
  height: 248px;
  position: relative;
  z-index: 2;
}
.inter-world {
  color: #ffffff;
  text-align: left;
  font: 900 130px/100% "Rubik", sans-serif;
  margin-left: -7px;
}
.expmetvers {
  color: #ffffff;
  text-align: left;
  font: 400 28px/150% "Rubik", sans-serif;
  margin-top: 24px;
  width: 598px;
}
.button {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button2 {
  user-select: none;
  background: transparent linear-gradient(90deg, #FAD6BC 0%, #8BF9BA 100%) 0% 0% no-repeat padding-box;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 12px 22px;
  gap: 6px;
  &:hover, &:focus {
    background: transparent linear-gradient(90deg, #FAD6BC -25%, #8BF9BA 125%) 0% 0% no-repeat padding-box;
  }
}
.join-to-metaverse {
  // letter-spacing: 0.68px;
  color: #000000;
  font-family: var(--hdr-font);
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 16px;
}
.frame-4109 {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: relative;
}
.ellipse-1 {
  background: #ffffff;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.arrow-right {
  max-width: 21.5px;
}
.join {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-4111 {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.group-28 {
  flex-shrink: 0;
  width: 90.67px;
  height: 32px;
  position: static;
}
.unsplash {
  background: linear-gradient(to left, #c4c4c4, #c4c4c4);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  &:not(:first-child) {
    margin-left: -16px;
  }
}
.circle-members-joined {
  background: transparent linear-gradient(90deg, #FCD8BE 0%, #8BF9BA 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}
._200-members-joined {
  text-align: left;
  letter-spacing: 0.68px;
  color: #FFFFFF;
  font-family: var(--hdr-font);
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
}
.menu {
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pasted-20230710-142852-1-1 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.nav_container {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.item {
  color: var(--text-color, #ffffff);
  text-align: left;

  font-family: var(--hdr-font);
  font-size: 16px;
  font-weight: 300;

  position: relative;
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
  &.active, &:active {
    color: var(--hover-color, #F3F5FB);
    text-decoration: underline;
  }
}

.desk-header-right-side {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 36px;
  position: relative;
}
.button-small {
  font-family: var(--hdr-font);
  font-size: 16px;
  font-weight: 400;
  background: transparent linear-gradient(90deg, #FAD6BC 0%, #8BF9BA 100%) 0% 0% no-repeat;
  transition: background 0.3s ease-in-out;
  border-radius: 7px;
  padding: 8px 18px;

  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  // letter-spacing: 0.68px;
  color: #000000;
  text-decoration: none !important;
  user-select: none;

  &:hover, &:focus {
    background: transparent linear-gradient(90deg, #FAD6BC -25%, #8BF9BA 125%) 0% 0% no-repeat;
  }
}
.log-in {
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sign-up {
  color: var(--text-color, #ffffff);
  text-align: left;

  font-family: var(--hdr-font);
  font-size: 16px;
  font-weight: 300;

  position: relative;
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
  &.active, &:active {
    color: var(--hover-color, #F3F5FB);
    text-decoration: underline;
  }

}
.language {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  text-transform: capitalize;
}
.en {
  text-align: left;
  font-family: var(--hdr-font);
  font-size: 16px;
  font-weight: 300;
  position: relative;

  color: var(--text-color, #ffffff);
  &:hover, &:focus {
    color: var(--hover-color, #F3F5FB);
  }
}
.icon-down {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.top_btn {
  &__container {

  }
  &__download {
    user-select: none;
    display: flex;
    height: 40px;
    padding: 13px 19px;
    align-items: center;
    gap: 12px;

    border-radius: 8px;
    background: #000000;
    svg {
      width: 25px;
      height: auto;
    }
    span {
      white-space: nowrap;
      color: #FFF;
      font-family: var(--hdr-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.68px;
    }
  }
}

.first_title {
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 8px #1F1F4CBF;
  font-size: 45px;
  font-family: var(--syne-font);
  transform: scaleX(1.1);
  transform-origin: left;
  font-weight: 600;
  letter-spacing: 0.46px;
  line-height: 42px;
  white-space: break-spaces;
}

.top_bg_video {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  height: 700px;
  width: 100%;
  top: 0px;
  left: 0px;
  object-fit: cover;
}
